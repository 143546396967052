
import Card from '@material-ui/core/Card';
// import Table from '@material-ui/core/Table';
// import TableBody from '@material-ui/core/TableBody';
// import TableCell from '@material-ui/core/TableCell';
// import TableContainer from '@material-ui/core/TableContainer';
// import TableRow from '@material-ui/core/TableRow';
// import Paper from '@material-ui/core/Paper';

import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import './style.css'
import React, { useState } from 'react'


import { Auth } from "aws-amplify";
import axios from 'axios';
import awsConfig from '../../awsConfig';
import AlertBox from './AlertBox'

const ForgotPasswordForm = (props) => {
  const [showAlert, toggleAlert] = useState(false);
  const [alertMsg, setAlertMsg] = useState('');
  const [alertTitle, setAlertTitle] = useState('Alert')
  const [showOk, setShowOk] = useState(true);
  
  const handleSendVerification = (e) => {
    e.preventDefault();
    props.toggleLoader(true);

    let errorMsg = `Email: ${props.email} is not registered with us. Please check the email address and retry.`
    let request = {
      "username": process.env.REACT_APP_AUTH_USERNAME,
      "password": process.env.REACT_APP_AUTH_TOKEN_PASS
    }
    axios.post(`${process.env.REACT_APP_csrBaseUrl}login`, request, true)
    .then((response) => {
      let token = response['headers'].authorization;
      let request = {
        "cognitoUserPool": awsConfig.aws_user_pools_id,
        "username": props.email
      }

      axios.post(`${process.env.REACT_APP_csrBaseUrl}memberportal/getuser`, request, {
        headers: {
          Authorization: token
        }
      })
      .then((resp) => {
        let netwellOrganization = Buffer.from(`NETWELL;${new Date()}`).toString('base64');
        if (resp.data.userStatus == "FORCE_CHANGE_PASSWORD") {
          props.toggleLoader(true);
          axios.post(`${process.env.REACT_APP_csrBaseUrl}csrportal/resetuser`, request, {
            headers: {
              'X-Tenant-Id': netwellOrganization,
              Authorization: 'Bearer ' + localStorage.getItem('NetwellbearerToken')
            },
          })
          .then((resp) => {
            console.log(resp);
            props.toggleLoader(false);
            toggleAlert(true);
            setAlertMsg(resp.data);
            setAlertTitle('Message');
            setShowOk(false);
            setTimeout(() => {
              toggleAlert(false);
              setAlertMsg('');
              setAlertTitle('Alert');
              setShowOk(true);
              window.location.href=`?username=${props.email}&password=`;
              props.toggleLoader(false);
            }, 2000);
          })
          .catch((err) => {
            props.toggleLoader(false);
            if (err.response.request.status != 409) {
              props.toggleLoader(true);
            } else {
              props.toggleLoader(false);
            }
            if (err.response && err.response.data && err.response.data.message) {
              setAlertMsg(err.response.data.message);
            } else {
              setAlertMsg("Please try again");
            }
          })
        } else {
          Auth.forgotPassword(props.email)
          .then(data => {
            console.log('send verification');
            console.log(data);
            props.toggleLoader(false);
            props.handleSendVerification(data.CodeDeliveryDetails.Destination)
          })
          .catch(err => {
            props.toggleLoader(false);
            console.log(err);
          })
        }
      })
      .catch((err) => {
        toggleAlert(true);
        setAlertMsg(errorMsg);
        props.toggleLoader(false);
      })
    })
    .catch((err) => {
      toggleAlert(true);
      setAlertMsg(errorMsg);
      props.toggleLoader(false);
    })
  }

  return (
    <div className="login">
      <CssBaseline />
      <Container maxWidth="xs">
        <Card className="login-card" style={{ marginTop: "50px" }}>
          <div className="logo">
            <img alt="logo" className="logo-custom" src={require('./images/Logo_AdminPortal.png')} />
          </div>
          <form className="main-form" onSubmit={handleSendVerification} >
            <h4>Forgot your password?</h4>
            <p>Enter your Email below and we will send a message to reset your password.</p>
            <div className="a-form-ctrl">
              <p className="">Email</p>
              <input
                className="a-input"
                type="email"
                placeholder="Email"
                name="username"
                required
                onChange={props.handleChange}
              />
            </div>
            <div>
              <button type="submit" className="a-btn" >
                Reset my password
              </button>
            </div>
          </form>
        </Card>
      </Container>
      <AlertBox showAlert={showAlert} alertMsg={alertMsg} alertTitle={alertTitle} showOk={showOk} closeAlert={() => {
        setAlertMsg('');
        toggleAlert(false)
      }} />
    </div>
  )
}

export default ForgotPasswordForm
