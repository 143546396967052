import React, { Component } from 'react';
import customStyle from '../../Assets/CSS/stylesheet_UHS';
import Grid from '@material-ui/core/Grid';
import Button from "@material-ui/core/Button";
import { Modal, Dropdown } from "react-bootstrap";
import { withStyles, createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import Loader from "../Commonscreens/loader";
import axios from "axios";
import customecss from "../Screens/AgentCreation.css";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from '@material-ui/core/TextField';
import Sample from '../Commonscreens/sampleTextField';
import configurations from "../../configurations";
import customStyleUHS from '../../Assets/CSS/stylesheet';
import MuiPhoneNumber from "material-ui-phone-number";

const CustomTextField = withStyles(theme => ({
    root: {
        '& .MuiFilledInput-root': {
            backgroundColor: '#f8f8f8',
            color: '#19191d',
            fontSize: '16px',
            lineHeight: '24px',
            textAlign: 'left',
            // height: '56px',
            borderColor: '#533278',
            '&:hover': {
                backgroundColor: '#f4f4f4',
                color: '#533278',
            },
            "&:hover:not($disabled):not($focused):not($error):before": {
                // hover
                borderBottom: '2px solid #533278'
            },

            '&.MuiFilledInput-underline:after': {
                borderBottom: '2px solid #533278'
            },
            '&.MuiFilledInput-underline.Mui-error:after': {
                //borderBottomColor: '#f44336'
                borderBottom: '2px solid #f44336',

            },
        },
        '&.MuiInputBase-formControl': {
            height: '56px',
        },

        '&.MuiSelect-selectMenu ': { paddingRight: '132px' },
        '& .Mui-focused': {

        },
        '&.MuiSelect-filled.MuiSelect-filled': { paddingRight: '132px', color: 'red' },
        '& .MuiInputLabel-filled': {
            wordBreak: 'normal',
            //   whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            // width: '101%',
            overflow: 'hidden'
        },

        '& label.Mui-focused': {
            color: '#533278',
            wordBreak: 'normal',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            width: 'auto',
            overflow: 'hidden'
        },

        '& p.MuiFormHelperText-contained': {
            margin: 0,
            fontSize: "12px",
            marginTop: '6px'
        }
    },
    select: { '&.MuiSelect-filled.MuiSelect-filled': { paddingRight: '132px', color: 'red' } },
}))(TextField);

const NextButton = withStyles(
    customStyle.doneBtn
)(Button);

const CustomButton = withStyles(
    customStyle.viewBtn
)(Button);

const GenBtn = withStyles(
    customStyleUHS.genrateBtn
)(Button);

class AgentCreate extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loaderShow: false,
            msgModal: false,
            brokerId: '',
            clientId: '',
            clientName: '',
            associationId: '',
            fname: '',
            lname: '',
            email: '',
            phone: '',
            userValid: true,
            errMsg: "",
            countryCode: '',
            refresh: false,
            List: [],
            code: null,
            validationFlag: false,
            errFname: '',
            associationIdList: ["Marketer 1","Marketer 2","Marketer 3","Marketer 4","Marketer 5","MDC","FMO 1","FMO 2","FMO 3","FMO 4","FMO 5","Call Center .5","Call Center .75","Call Center 1","Call Center 2","Call Center 3","Call Center 4", "Call Center 5", "Call Center 6","Call Center 7","Call Center 8","Call Center 9","Call Center 10", "Call Center 11", "ZEROCOMM"],
        };
    }

    componentDidMount() {
        this.getCountryCode();
        this.getAllData();

    }

    getAllData = () => {
        fetch(configurations.agentBaseUrl + '/enrollment/getAllClient/')
            .then(response => response.json())
            .then(data => {
                this.setState({ List: data.response })
            }
            );

    }

    // -----------------Country Code for Mobile no.------------

    getCountryCode = () => {
        axios.get('https://ipapi.co/json/').then((response) => {
            if (response && response.data) {
                let data = response.data;
                this.setState({
                    countryCode: data.country_calling_code
                }, () => console.log(this.state.countryCode));
            }
        }).catch((error) => {
            console.log(error);
        });
    }

    // -----------------Agent Registration ------------

    addAgentDetails = () => {
        this.setState({
            loaderShow: true,
            msgModal: false
        });

        let obj = {
            "firstName": this.state.fname,
            "lastName": this.state.lname,
            "phone":  this.state.phone,
            "email": this.state.email,
            "clientId": this.state.clientId,
            "associationId": this.state.associationId,
            "brokerId": this.state.brokerId
        }

        axios.get(configurations.agentBaseUrl + '/agentlogin/checkBrokerId/' + this.state.brokerId)
            .then(response => {
                if (response.data.code === 200) {
                    axios.post(configurations.agentBaseUrl + '/agentlogin/agentRegistration', JSON.parse(JSON.stringify(obj)))
                        .then(response => {
                            if (response.data.code === 200) {
                                this.setState({
                                    loaderShow: false,
                                    msgModal: true,
                                    errMsg: "Agent added successfully!",
                                    refresh: true,
                                    code: response.data.code
                                });
                            }
                            else if (response.data.code === 409) {
                                this.setState({
                                    loaderShow: false,
                                    msgModal: true,
                                    errMsg: "Agent is already registered with this email !",
                                    refresh: true,
                                    userValid: true,
                                    code: response.data.code
                                });
                            } else {
                                this.setState({
                                    loaderShow: false,
                                    msgModal: true,
                                    errMsg: "Internal Server Error !",
                                    refresh: true,
                                    code: response.data.code
                                });
                            }
                        });
                } else if (response.data.code === 202) {
                    this.setState({ brokerId: 'Agent already registerd!', userValid: true, validationFlag: true, loaderShow: false, code: response.data.code })
                } else {
                    this.setState({ msgModal: true, brokerId: 'Internal Server Error  !', userValid: true, loaderShow: false, code: response.data.code })
                }
            })
    }


    // --------------- OnChange Text data---------------------------

    setUserValue = (value, isValid, parentDetails) => {
        if (parentDetails.name === 'firstname') {
            if (isValid) {
                this.setState({ fname: value }, () => this.checkVal())
            } else {
                console.log('Invalid value---------' + value)
                this.setState({ fname: '' }, () => this.checkVal())
            }
            // this.checkVal();
        } else if (parentDetails.name === 'lastname') {
            if (isValid) {
                this.setState({ lname: value }, () => this.checkVal())
            } else {
                this.setState({ lname: '' }, () => this.checkVal())
            }
            // this.checkVal();
        } else if (parentDetails.name === 'email') {
            if (isValid) {
                this.setState({ email: value }, () => this.checkVal())
            } else {
                this.setState({ email: '' }, () => this.checkVal())
            }

        } else if (parentDetails.name === 'phone') {
            if (isValid) {
                this.setState({ phone: value }, () => this.checkVal())
            } else {
                this.setState({ phone: '' }, () => this.checkVal())
            }
            this.checkVal();
        } else if (parentDetails.name === 'associationid') {
            if (isValid) {
                this.setState({ associationId: value }, () => this.checkVal())
            } else {
                this.setState({ associationId: '' }, () => this.checkVal())
            }
            //this.setState({associationId:value},()=>this.checkVal())

        }
        else if (parentDetails.name === 'brokerid') {
            if (isValid) {
                this.setState({ brokerId: value }, () => this.checkVal())
            }
            else {
                this.setState({ brokerId: '' }, () => this.checkVal())
            }
        }
    }

    // -------------Vallidation--------------------------

    checkVal() {
        if (this.state.fname !== '' && this.state.lname !== '' && this.state.email !== '' && this.state.phone !== '' && this.state.associationId !== '' && this.state.brokerId !== '' && this.state.phone.length == 17) {
            this.setState({ userValid: false })
        } else {
            this.setState({ userValid: true })
        }


    }

    // ----------------Drop down value change-----------------------

    changeData = (event) => {
        var data = this.state.List
        var index = data.find(function (cData) {
            return cData.clientName == event.target.value
        })
        this.setState({
            // associationId: index.association,
            clientId: index.clientId,
            clientName: event.target.value
        })
    }

    // ---------------Clear Data--------------------

    clearData = () => {

        this.setState({
            fname: '',
            lname: '',
            phone: '',
            email: '',
            associationId: '',
            brokerId: '',
            clientName: '',
            userValid: true,
            errFname: ''
        })

        // window.location.reload()
    }

    handleOk = () => {
        if (this.state.code === 200) {
            this.setState({ msgModal: false, loaderShow: false }, () => this.clearData());
        }
        else {
            this.setState({ msgModal: false, loaderShow: false });
        }

    }

    handleBack = () => {
        this.props.onClick();
    }


    render() {
        console.log(this.state.phone)
        return (
            <div style={{ marginLeft: '11%' }}>
                {/* style={{height:'100vh'}} */}
                {
                    this.state.loaderShow ? <Loader></Loader> : ''
                }
                <div style={{ padding: '2%', width: '830px', marginTop: '0%' }}>
                    {/* backgroundColor: 'rgb(191, 191, 191)' */}
                    <Grid >
                        <form id='form'  >
                            <div style={customStyle.HomeContainer}>
                                <div style={{ backgroundColor: 'rgb(51,175,176)', dispaly: 'flex', alignItems: 'flex-start', justifyContent: 'space-between', width: '100%', padding: '15px', fontWeight: 'bold' }} >Agent Onboarding</div>

                                <div style={customStyle.HomeMAinChild}>
                                    <div style={customStyle.HomeTextContiner}>

                                        <div style={{ width: '280px', margin: '10px' }}>
                                            <Sample setChild={this.setUserValue.bind(this)} reqFlag={true} name={'firstname'} label={'First Name'} value={this.state.fname} disable={false} style={customStyle.textFieldWrpAgent} length={25} fieldType={'fullName'} errMsg={'Enter valid first name'} helperMsg={'First name required'} parentDetails={{ name: 'firstname' }}></Sample>
                                        </div>
                                        <div style={{ width: '280px', margin: '10px' }}>
                                            <Sample setChild={this.setUserValue.bind(this)} reqFlag={true} name={'lastname'} label={'Last Name'} value={this.state.lname} disable={false} style={customStyle.textFieldWrpAgent} length={25} fieldType={'fullName'} errMsg={'Enter valid last name'} helperMsg={'Last name required'} parentDetails={{ name: 'lastname' }}></Sample>
                                        </div>

                                    </div>

                                    {/* ========================================= */}
                                    <div style={customStyle.HomeTextContiner}>
                                        <div style={{ width: '280px', margin: '10px' }}>
                                            <Sample setChild={this.setUserValue.bind(this)} reqFlag={true} name={'email'} label={'Email ID'} value={this.state.email} disable={false} style={customStyle.textFieldWrpAgent} length={50} fieldType={'email'} errMsg={'Enter valid email Id'} helperMsg={'Email Id required'} parentDetails={{ name: 'email' }}></Sample>
                                        </div>

                                        <div style={{ width: '280px', margin: '10px' }}>

                                        <MuiPhoneNumber
                                                name={"phone"}
                                                required id="filled-required"
                                                label={"Mobile No."}
                                                data-cy="user-phone"
                                                variant="filled"
                                                defaultCountry={"us"}
                                                onlyCountries={["us"]}
                                                autoComplete='off'
                                                value={this.state.phone}
                                                length={17}
                                                onChange={(e)=>this.setUserValue(e, true, {name: 'phone'})}
                                                disable={false} style={customStyle.textFieldWrpAgent} fieldType={'phone'} 
                                                errMsg={'Enter valid mobile no.'} helperMsg={'Mobile no. required'}
                                            />
                                            {this.state.phone.length!==17 && <p class="MuiFormHelperText-root MuiFormHelperText-contained Mui-required">Enter valid mobile no.</p>}
                                   {/* <Sample setChild={this.setUserValue.bind(this)} reqFlag={true} name={'phone'} label={'Mobile No.'} value={this.state.phone} disable={false} style={customStyle.textFieldWrpAgent} length={10} fieldType={'phone'} errMsg={'Enter valid mobile no.'} helperMsg={'Mobile no. required'} parentDetails={{ name: 'phone' }}></Sample> */}
                                        </div>
                                    </div>

                                    {/* ================================================= */}
                                    <div style={customStyle.HomeTextContiner}>
                                        <div style={{ width: '280px', margin: '10px' }}>
                                            <CustomTextField
                                                select
                                                label="Parent Company"
                                                name='clientid'
                                                variant="filled"
                                                required
                                                value={this.state.clientName}
                                                helperText={this.state.clientName == '' ? 'Select Parent Company' : ''}
                                                style={customecss.dropDown}
                                                onChange={(event) => this.changeData(event)}
                                            >
                                                {this.state.List.map((option, index) => (
                                                    <MenuItem key={index} value={option.clientName}  >
                                                        {option.clientName}
                                                    </MenuItem>
                                                ))}
                                            </CustomTextField>
                                            {/* <CommonDropDwn setValue={this.setValue.bind(this)} name={'ClientId'} label={'Client Id'} value={this.state.cid} disable={false} style={customStyle.dropDown}  fieldType={'dropDwn'}  helperMsg={'Select client id'} List={this.state.List}  parent={{name:'ClientId'}}></CommonDropDwn> */}
                                        </div>

                                        <div style={{ width: '280px', margin: '10px' }}>
                                        <CustomTextField
                                                select
                                                label="Contract Type"
                                                reqFlag={true} name={'associationid'}
                                                variant="filled"
                                                fieldType={'treatment'} errMsg={'Enter Contract Type'} helperMsg={'Contract Type Required'} parentDetails={{ name: 'associationid' }}
                                                required
                                                value={this.state.associationId}
                                                helperText={this.state.associationId == '' ? 'Select Contract Type' : ''}
                                                style={customecss.dropDown}
                                                onChange={(e)=>{this.setState({associationId:e.target.value})}}
                                            >
                                                {this.state.associationIdList.map((option, index) => (
                                                    <MenuItem key={index} value={option}  >
                                                        {option}
                                                    </MenuItem>
                                                ))}
                                            </CustomTextField>
                                            {/* <Sample setChild={this.setUserValue.bind(this)} reqFlag={true} name={'associationid'} label={'Contract Type'} value={this.state.associationId} disable={false} style={customStyle.textFieldWrp} length={25} fieldType={'treatment'} errMsg={'Enter Contract Type'} helperMsg={'Contract Type Required'} parentDetails={{ name: 'associationid' }}></Sample> */}
                                        </div>
                                    </div>

                                    {/* ===================================================== */}

                                    <div style={customStyle.HomeTextContiner}>
                                        {/* errMsg={'Agent already register!'} */}
                                        <div style={{ width: '280px', marginLeft: '-54px', marginTop: '10px', marginRight: '-244%' }}>
                                            <Sample id="bkid" setChild={this.setUserValue.bind(this)} reqFlag={true} name={'brokerid'} label={'Marketer ID'} value={this.state.brokerId} disable={false} style={customStyle.textFieldWrp2} length={20} fieldType={'treatment'} helperMsg={'Marketer ID Required'} errMsg={'Enter valid Marketer ID'} parentDetails={{ name: 'brokerid' }}></Sample>
                                        </div>
                                    </div>
                                    <div style={{ display: 'flex', width: '100%' }}>
                                        <div style={{ width: '100%', marginLeft: '10%' }}>
                                            <GenBtn style={{ width: '150px', height: '40px', margin: '10px' }} color="primary" variant="contained" onClick={() => this.handleBack()}>Back To Home</GenBtn>
                                            <NextButton style={{ width: '96px', height: '40px', margin: '10px' }} onClick={this.clearData}>CANCEL</NextButton>
                                            <NextButton style={{ width: '96px', height: '40px', margin: '10px' }} disabled={this.state.userValid} onClick={this.addAgentDetails}>Submit</NextButton>
                                        </div>
                                    </div>
                                </div>





                                {/* <div style={customStyle.HomeTextContiner}>
                                        <div style={{width:'100%',marginTop:'-17px', marginRight:'-244%'}}>
                                        
                                        <NextButton style={{ width: '96px', height: '40px',marginLeft:'112%',marginTop:'0px'}} disabled={this.state.userValid} onClick={this.addAgentDetails}>SUBMIT</NextButton>
                                        <NextButton style={{ width: '96px', height: '40px',marginTop:'-66px',marginLeft:'56%'}} onClick={this.clearData}>CANCEL</NextButton>
                                        <NextButton style={{ width: '122px', height: '40px',marginTop:'-66px',marginLeft:'-72%'}} onClick={()=>this.handleBack()}>BACK TO HOME</NextButton>
                                        </div>
                                        </div> */}
                            </div>
                        </form>


                        {/*=================================== Message Model ======================================*/}


                        <Modal size="md" show={this.state.msgModal} onHide={this.handleOk} style={{ marginTop: '10%' }} backdrop='static'>
                            <Modal.Header style={customStyle.modal_header} closeButton>
                                <Modal.Title>Message</Modal.Title>
                            </Modal.Header>
                            <Modal.Body style={{ padding: '15px' }}>
                                {
                                    this.state.loaderShow ? <Loader></Loader> : ''
                                }

                                <div style={customStyle.HomeContainer1}>
                                    <div style={customStyle.HomeMAinChild}>
                                        {this.state.errMsg}
                                    </div>
                                </div>

                            </Modal.Body>
                            <Modal.Footer>
                                <CustomButton style={{ height: '40px' }} onClick={this.handleOk}>Ok</CustomButton>
                            </Modal.Footer>
                        </Modal>
                        {/*=================================== Message Model ======================================*/}
                    </Grid>

                </div>

            </div>
        );
    }
}

export default AgentCreate;