import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    dialogTitle: {
        minWidth: '400px',
        borderBottom: '1px solid #80808057',
        padding: '10px 24px',
        fontWeight: 500
    }

}))
export default function AlertDialog(props) {
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        props.handleClose()
    };


    const disagree = () => {
        props.handleClose()
    }
    const accept = () => {
        props.accept()
    }
    const classes = useStyles();

    const getMsg = () => {
        // { props.selectedPaymentMode == 'RefundAdjust' ? 'Adjust' : 'Refund' }
        switch (props.selectedPaymentMode) {
            case 'RefundAdjust':
                return 'Adjust'
            case 'AccountOnFile':
                return 'Charge'
            case 'Refund':
                return 'Refund'
            default:
                return 'Charge'
        }

    }
    return (
        <div>

            <Dialog
                open={props.open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <h6 className={classes.dialogTitle}>Confirm</h6>
                <DialogContent>
                    {

                        props.paymentRequest.displayAmount &&
                        <DialogContentText id="alert-dialog-description" style={{
                            color: 'black'
                        }}>

                            Are you sure want to {getMsg()} <span style={{ fontWeight: 'bold' }}>${Number(props.paymentRequest.displayAmount).toFixed(2)}</span> to <span style={{ fontWeight: 'bold' }}>{`${props.memberDetails.prefix || ""} ${props.memberDetails.firstName} ${props.memberDetails.middleName || ""} ${props.memberDetails.lastName} ${props.memberDetails.suffix || ""}`}</span>?
</DialogContentText>
                    }

                </DialogContent>
                <DialogActions>
                    <Button onClick={disagree} color="secondary" style={{ textTransform: 'none' }}>
                        Cancel
                    </Button>
                    <Button onClick={accept} color="primary" style={{ textTransform: 'none' }}>
                        Confirm Payment
                     </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}