import React, { Component } from 'react';
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
// import Amplify from '@aws-amplify/core';
// import Auth from '@aws-amplify/auth';
import axios from 'axios';
import Amplify, { Auth } from 'aws-amplify';
import '../../Assets/CSS/window.css';
import Header from '../Commonscreens/Header'
import MainCard from '../Screens/Maincard'
import DigitalCard from '../../Assets/Images/Digital Health Card.png';
import Provider from '../../Assets/Images/Find a Provider.png';
import Document from '../../Assets/Images/Documents@3x.png';
import AgentAddActive from '../../Assets/Images/add_agent_active.svg';
import MultipleAgentActive from '../../Assets/Images/add_multiple_agents_active.svg';
import AuditActive from '../../Assets/Images/audit_log_active.svg';
import EncodeURLActive from '../../Assets/Images/encode_urls_active.svg';
import AgentAddInactive from '../../Assets/Images/add_agent.svg';
import MultipleAgentInactive from '../../Assets/Images/add_multiple_agents.svg';
import AuditInactive from '../../Assets/Images/audit_log.svg';
import ReportInactive from '../../Assets/Images/reports_new.svg';
import ReportActive from '../../Assets/Images/reports_active.svg';
import SearchInactive from '../../Assets/Images/search_user.svg';
import SearchActive from '../../Assets/Images/search_user_active.svg';
import EncodeURLInactive from '../../Assets/Images/encode_urls.svg';
import PaymentInactive from '../../Assets/Images/payments.svg';
import PaymentActive from '../../Assets/Images/payments_active.svg';
import PaidThruReport from '../../Assets/Images/reports.svg';
import PaidThruReportActive from '../../Assets/Images/reports_active.svg';
import SendMailIcon from '../../Assets/Images/mail.svg';
import SendMailActiveIcon from '../../Assets/Images/mail_active.svg';
import CognitoDashboardIcon from '../../Assets/Images/cognito_dashboard.svg';
import CognitoDashboardActiveIcon from '../../Assets/Images/cognito_dashboard_active.svg';
import CognitoSettingIcon from '../../Assets/Images/cognito_settings.svg';
import CognitoSettingActiveIcon from '../../Assets/Images/cognito_settings_active.svg';
import PortalUserIcon from '../../Assets/Images/portal_user_mgmt.svg';
import PortalUserActiveIcon from '../../Assets/Images/portal_user_mgmt_active.svg';
import Loader from '../authentication/loader';
import { Modules } from '../../App';
import { clearState } from '../../store/slice/notificationSlice';
import { loginWithPromise } from '../../store/slice/authSlice';
import Reports from './Reports';




// const HomeContainer
class Home extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeModule: Modules.Home,
            menuItems: [
                {
                    name: 'Encode URLs',
                    module: Modules.encodeURL,
                    cardImage: EncodeURLInactive,
                    cardHoverImage: EncodeURLActive,
                    showCard: false,
                },
                {
                    name: 'OnBoard Agent',
                    module: Modules.OnBoardAgent,
                    cardImage: AgentAddInactive,
                    cardHoverImage: AgentAddActive,
                    showCard: false,
                },
                {
                    name: 'Audit Logs',
                    module: Modules.AuditLogs,
                    cardImage: AuditInactive,
                    cardHoverImage: AuditActive,
                    showCard: false,
                },
                {
                    name: 'Payment',
                    module: Modules.Payment,
                    cardImage: PaymentInactive,
                    cardHoverImage: PaymentActive,
                    showCard: false,
                },
                // {
                //     name: 'Paid Thru Defaulters Report',
                //     module: Modules.PaidThruReport,
                //     cardImage: PaidThruReport,
                //     cardHoverImage: PaidThruReportActive,
                //     showCard: false,
                // },
                // {
                //     name: 'Members Dashboard',
                //     module: Modules.MemberDashboard,
                //     cardImage: PaidThruReport,
                //     cardHoverImage: PaidThruReportActive
                // },
                // {
                //     name: 'Send Mass Emails',
                //     module: Modules.Notification,
                //     cardImage: SendMailIcon,
                //     cardHoverImage: SendMailActiveIcon,
                //     showCard: false,
                // },
                // {
                //     name: 'Cognito Dashboard',
                //     module: Modules.CognitoDashboard,
                //     cardImage: CognitoDashboardIcon,
                //     cardHoverImage: CognitoDashboardActiveIcon,
                //     showCard: false,
                // },
                {
                    name: 'Portal User Management',
                    module: Modules.CognitoSetting,
                    cardImage: PortalUserIcon,
                    cardHoverImage: PortalUserActiveIcon,
                    showCard: false,
                },
                {
                    name: 'Search Prospects or Manage Prospects',
                    module: Modules.userSearch,
                    cardImage: SearchInactive,
                    cardHoverImage: SearchActive,
                    showCard: false,
                },
                {
                    name: 'Operations Report',
                    module: Modules.Reports,
                    cardImage: ReportInactive,
                    cardHoverImage: ReportActive,
                    showCard: false,
                },
                // {
                //     name: 'Operations Dashboard',
                //     module: Modules.OperationsDashboard,
                //     cardImage: CognitoDashboardIcon,
                //     cardHoverImage: CognitoDashboardActiveIcon,
                //     showCard: false,
                // },
                // {
                //     name: 'Create Channel',
                //     module: Modules.createChannel,
                //     cardImage: AgentAddInactive,
                //     cardHoverImage: AgentAddActive,
                //     showCard: false,
                // },
                
            ],
            showLoader: false,
            cardsList: "",
        }

        this.idleTimer = null

    }

    componentDidMount() {
        this.props.clearState();
        Auth.currentAuthenticatedUser()
            .then((user) => {
                localStorage.setItem('email', user.attributes.email);
                this.getAdminCards(user.attributes.email);
            }).catch((err) => {
                console.log(err)
                sessionStorage.setItem('isLogged', false);
                this.appLogout();
            });
    }

    getAdminCards = (email) => {
        this.setState({
            showLoader: true
        })
        axios.get(`${process.env.REACT_APP_CARDS_URL}/admin/cards/${email}/ADMIN/NETWELL`)
            .then((resp) => {
                let response = resp.data.response.cards;
                const cards = response.split(',');
                this.setState({cardsList: cards})
                let menuItems = [...this.state.menuItems];
                cards.forEach((card) => {
                    menuItems.forEach((item) => {
                        if (item.name == card) {
                            item.showCard = true;
                        }
                    });
                })
                this.setState({
                    menuItems: menuItems,
                    showLoader: false
                })
            }).catch((err) => {
                console.log(err, 'failed to load templates');
                this.setState({
                    showLoader: false
                })
            });
    }

    loadComponent = (moduleKey) => {
        console.log('============== loadComponent =====================');
        console.log(moduleKey);
        // this.setState({
        //     activeModule: moduleKey
        // })

        this.props.history.push(moduleKey)
    }

    handleBack = () => {
        this.setState({
            activeModule: Modules.Home
        });
    }



    appLogout = () => {
        Auth.signOut();
        localStorage.clear();
        sessionStorage.clear();
        window.location.href = '/login';
    }



    render() {
        let currentScreen;


        return (
            <div className="bottommaincontainer_left">
                <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '25%', flexWrap: 'wrap', minHeight: '40vh' }}>

                {
                    this.state.showLoader ?
                        <Loader showLoader={this.state.showLoader} />
                        :
                        this.state.cardsList == "" ?
                            <div>
                                <text style={{lineHeight: "35vh", fontWeight: "600"}}>You currently do not have access.</text>
                            </div>
                        :
                        this.state.menuItems.map((menu, index) => {
                            if (menu.showCard) {
                                return (
                                    <MainCard key={index}
                                        name={menu.name}
                                        loadComponent={this.loadComponent}
                                        cardImage={menu.cardImage}
                                        moduleKey={menu.module}
                                        cardHoverImage={menu.cardHoverImage} />
                                )
                            } else {
                                return null
                            }
                        })
                    }
                </div>
            </div>
        )
    }

}
const mapStateToProps = (state) => {
    return {

    }
};
// export default connect({}, { clearState })(withRouter(Home));
export default withRouter(connect(mapStateToProps, { clearState })(Home));