import { createSlice } from '@reduxjs/toolkit';
import Axios from 'axios';


export const authSlice = createSlice({
    name: 'authentication',
    initialState: {
        csrToken: null,
        showEmailAdmin:false
    },
    reducers: {
        setCSRToken: (state, action) => {
            state.csrToken = action.payload
        },

    }
});

export const { setCSRToken } = authSlice.actions;

export const csrServiceLoginAction = () => {
    return async dispatch => {

        let token = null
        let request = {
            "username": process.env.REACT_APP_GETWAY_TOKEN_USERNAME,
            "password": process.env.REACT_APP_GETWAY_TOKEN_PASS
        }
        Axios.post(process.env.REACT_APP_csrBaseUrl + 'login', request).then((resp) => {
            if (resp.headers.authorization) {
                token = resp.headers.authorization
                sessionStorage.setItem('csrtoken', token)
                dispatch(setCSRToken(token))
            }
        }).catch((err) => {
            console.log('err', err)
        });

    }
}

export const loginWithPromise = () => {
    return new Promise((resolve, reject) => {
        let token = null
        let request = {
            "username": process.env.REACT_APP_GETWAY_TOKEN_USERNAME,
            "password": process.env.REACT_APP_GETWAY_TOKEN_PASS
        }
        setTimeout(() => {
            Axios.post(process.env.REACT_APP_csrBaseUrl + 'login', request).then((resp) => {
                if (resp.headers.authorization) {
                    token = resp.headers.authorization
                    sessionStorage.setItem('csrtoken', token)
                    resolve(token)
                }
            }).catch((err) => {
                reject(err);
            });
        }, 100);

    })
}
export default authSlice.reducer;